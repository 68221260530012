import { useRef, useState } from 'react';
import { EditDeleteCellRenderer, UserProfileCellRenderer } from '@components';
import { useDock } from '@react';
import Axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Dropdown, DropdownButton, Card, Col, Modal, ModalBody, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { PagedModel } from '../../models/PagedModel';
import { Api, IApiResponse } from '../../services/Api';
import AdminAddUser from '../AdminPortal/AdminUsers/AdminAddUser';
import { userMgmtColumnDefs, IAcctMgmtData } from './columnDefs';
import { emitter, EVENTS } from './utils';

function userMgmt({ app }) {
  //useState Hooks
  const [rowData, setRowData] = useState(new PagedModel());
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gridKey, setGridKey] = useState(0);
  const { renderDock, closeDock } = useDock();
  const dockRef = useRef(null);
  const csvFileName = 'User_Management_Export';

  //Variables
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const id = 'UserManagement';

  const renderAddUser = (inviteType, app) => {
    renderDock({
      render: () => (
        <AdminAddUser
          ref={dockRef}
          closeDock={closeDock}
          inviteType={inviteType}
          appIdSelected={app.id}
          appNameSelected={app.name}
        />
      ),
    });
  };

  const fetchData = async (payload, csvExport, queryState) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);

    const requestData = {
      search: queryState.filter,
      sort: 'username asc', // Adjust this based on your sorting requirements
    };

    Api.get(
      `/applications/${app?.id}/users?top=${
        csvExport ? rowData.count : queryState.pageSize
      }&skip=${queryState.skip()}&search=${queryState.filter}&sort=${queryState.orderBy()}`,
      {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      },
    )
      .then((res: AxiosResponse) => {
        const data: IApiResponse<IAcctMgmtData> = res.data;
        setRowData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });

    // Help create call to export Users
    if (csvExport) {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      };

      Api.post(`/applications/${app?.id}/users/export`, requestData, config)
        .then((res) => {
          setCsvData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  emitter.on(EVENTS.REFETCH_USERS, () => {
    setGridKey((prevKey) => prevKey + 1);
  });

  const handleDeleteSuccess = () => {
    // Update the rowData count by decrementing it by 1
    setRowData((prevRowData) => {
      const newCount = prevRowData.count - 1;
      return { ...prevRowData, count: newCount };
    });
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="h-100">
          <Col className="d-flex flex-column h-100">
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-end">
                  <DropdownButton
                    id="Invite Users"
                    className=""
                    variant="secondary"
                    title="Invite Users"
                  >
                    <Dropdown.Item as="button" onClick={() => renderAddUser('CSV', app)}>
                      Via CSV
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => renderAddUser('EMAIL', app)}>
                      Via Email
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col>
            </Row>
            <Row className="h-100">
              <Col className="h-100">
                <ServerSideGrid
                  key={gridKey}
                  id={id}
                  columnDefs={userMgmtColumnDefs(handleDeleteSuccess)}
                  rowData={rowData}
                  csvData={csvData}
                  csvFileName={csvFileName}
                  isLoading={isLoading}
                  disabledFilters={[]}
                  filterControl={false}
                  searchControl={true}
                  viewingControl={true}
                  pagerControl={true}
                  exportControl={true}
                  initQueryParams={new PagedQueryParams(id)}
                  handleFetchData={fetchData}
                  gridProps={{
                    frameworkComponents: {
                      EditDeleteCellRenderer: EditDeleteCellRenderer,
                      UserProfileCellRenderer: UserProfileCellRenderer,
                    },
                    suppressClickEdit: true,
                    editType: 'fullRow',
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(userMgmt);
