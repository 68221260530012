import '../../scss/comps/Swal.scss';
import './AssetContainer.scss';
import { debounce } from 'lodash';
import { get } from 'lodash';
import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Loading, Text, toast } from '../../components';
import { Api, favoriteService } from '../../services';
import AssetScreen from './AssetScreen';
import DeleteModal from './DeleteModal';
import { emitter, EVENTS } from './utils';

const MySwal = withReactContent(Swal);

class AssetContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      asset: null,
      beacons: [],
      beaconFrequencies: [],
      selectedBeacons: [],
      map: null,
      location: null,
      keywords: [],
      identifiers: [],
      identifiersLength: null,
      triggers: [],
      places: [],
      isFavorite: false,
      error: null,
      scans: [],
      isRestoringAsset: false,
      isDeleteModalOpen: false,
    };

    this.cancelToken = Api.CancelToken;
    this.source = this.cancelToken.source();
  }

  componentDidMount() {
    this.getData();
    this.checkIsFavorite();
    emitter.on(EVENTS.REFETCH, this.refetch);
  }

  componentDidMount() {
    // If there's a passed assetName from the previous page, store it in the state
    const assetName = this.props.location?.state?.assetName || null;
    if (assetName) {
      this.setState({ asset: { name: assetName } });
    }

    // Then proceed with fetching the asset data as usual
    this.getData();
  }

  componentWillUnmount() {
    emitter.removeListener(EVENTS.REFETCH, this.refetch);
    this.abortAll();
  }

  getData = async ({ useLoading = true } = {}) => {
    this.setState({ isLoading: useLoading, error: null });
    const { assetId } = this.props.match.params;

    try {
      const { data: apiAsset } = await Api.get(`v2/assets/${assetId}`, {
        cancelToken: this.source.token,
      });

      // Continue with other API calls (places, beacons, scans, etc.)
      Api.get(`/places?1000000000`, {
        cancelToken: this.source.token,
      }).then(({ data }) => {
        this.setState({ places: data });
      });

      Api.get(`v2/assets/${assetId}/readings`, {
        cancelToken: this.source.token,
      }).then(({ data }) => {
        data.forEach((beacon) => {
          beacon.assetId = apiAsset.id;
          const matchingBeacon = apiAsset.beacons.find((apiBeacon) => apiBeacon.id === beacon.id);
          if (matchingBeacon) {
            beacon.abilities = matchingBeacon.abilities;
          }
          beacon.readings.forEach((reading) => {
            reading.beacon = beacon;
          });
        });
        this.setState({ beacons: data });
      });

      Api.get(`v2/assets/${assetId}/scans`, {
        cancelToken: this.source.token,
      }).then(({ data }) => {
        this.setState({ scans: data });
      });

      Api.get(`v2/assets/${assetId}/lastLocation`, {
        cancelToken: this.source.token,
      })
        .then(({ data }) => {
          const location = {
            x: data.coordinates.x,
            y: data.coordinates.y,
          };
          if (data.mapId) {
            Api.get(`/maps/${data.mapId}/layout?trackView=false`, {
              cancelToken: this.source.token,
            }).then(({ data }) => {
              this.setState({ map: data, location: location });
            });
          }
        })
        .catch(() => {
          // No last location available, handle it silently
        });

      const asset = {
        id: apiAsset.id,
        assetType: apiAsset.assetType,
        name: apiAsset.name,
        timestamp: apiAsset.lastCheckin,
        status: apiAsset.status,
        mapName: get(apiAsset, 'location.mapName'),
        placeName: get(apiAsset, 'location.places[0].name'),
      };

      const { keywords, identifiers } = apiAsset;

      this.setState({
        isLoading: false,
        asset: asset,
        keywords: keywords,
        identifiers: identifiers,
        identifiersLength: identifiers.length,
      });
    } catch (error) {
      // Axios wraps errors in an object, and you can check for 404 status
      if (error.response && error.response.status === 404) {
        // If it's a 404, handle as AssetDeletedError
        this.setState({
          isLoading: false,
          error: new AssetDeletedError(), // Pass the custom error
        });
      } else {
        // Handle other generic errors
        this.setState({
          isLoading: false,
          error: {
            name: 'GenericError',
            message: 'Request failed. Please try again.',
          },
        });
      }
    }
  };

  /**
   * @desc Take each beacons readings and call api to get readings data for given time.
   * @param {Array} beacons Beacons
   * @param {moment} startDate Start Date
   * @param {moment} endDate End Date
   */
  getBeaconReadings = async (selectedBeacons, startDate, endDate, params) => {
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    const promises = selectedBeacons.map((reading) =>
      Api.get(
        `/beacons/${reading.beacon.id}/readings/${reading.abilityId}?start=${startDateStr}&end=${endDateStr}`,
        params,
      ),
    );
    const res = await Promise.all(promises);
    if (params) return res;

    const data = res.reduce((accum, currRes, index) => {
      return accum.concat({
        beacon: selectedBeacons[index].beacon,
        ability: selectedBeacons[index].ability,
        readings: currRes.data, //.slice(0, 100),
      });
    }, []);

    return data;
  };

  getBeaconReadingsExport = async (selectedBeacons, startDate, endDate, userTempPref, params) => {
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    const promises = selectedBeacons.map((reading) => {
      return Api.get(
        `/beacons/${reading.beacon.id}/readings/${reading.abilityId}/export?useFahrenheit=${userTempPref}&start=${startDateStr}&end=${endDateStr}`,
        params,
      );
    });

    const res = await Promise.all(promises);
    if (params) return res;

    const data = res.reduce((accum, currRes, index) => {
      return accum.concat({
        beacon: selectedBeacons[index].beacon,
        ability: selectedBeacons[index].ability,
        readings: currRes.data, //.slice(0, 100),
      });
    }, []);

    return data;
  };

  // #region FAVORITE
  favoriteAsset = async () => {
    const { assetId } = this.props.match.params;

    await favoriteService.favoriteAsset(assetId);
    this.checkIsFavorite();
  };

  unfavoriteAsset = async () => {
    const { assetId } = this.props.match.params;

    await favoriteService.unfavoriteAsset(assetId);
    this.checkIsFavorite();
  };

  checkIsFavorite = async () => {
    const { assetId } = this.props.match.params;

    const { data } = await favoriteService.getFavorites(this.props.app.id);
    const isFavorite = data.find(
      (fav) => fav.itemType === 'Asset' && fav.itemId === Number(assetId),
    );

    this.setState({ isFavorite: !!isFavorite });
  };
  // #endregion FAVORITE

  onChangeKeywords = (keywords) => {
    this.setState({ keywords }, () => {
      this.updateAsset();
    });
  };

  onChangeIdentifiers = (newIdentifiers) => {
    const currentState = JSON.parse(JSON.stringify(newIdentifiers));
    const newState = JSON.parse(JSON.stringify(newIdentifiers));
    if (currentState !== newState) {
      this.setState(
        { identifiers: newIdentifiers, identifiersLength: newIdentifiers.length },
        () => {
          this.updateAsset();
        },
      );
    }
  };

  setAsset = (asset) => {
    this.setState(
      {
        asset: {
          ...this.state.asset,
          ...asset,
        },
      },
      () => {
        this.updateAsset();
      },
    );
  };

  updateAsset = debounce(async () => {
    const { asset, keywords, identifiers } = this.state;

    const mappedIdentifiers = identifiers.map((row) => [row.key, row.value]);
    const updatedAsset = {
      name: asset.name,
      keywords,
      movementThreshold: asset.movementThreshold,
      identifiers: mappedIdentifiers,
    };

    try {
      await Api.put(`/assets/${asset.id}`, updatedAsset, {
        cancelToken: this.source.token,
      });
      toast.success('Successfully updated asset!');
    } catch (err) {
      toast.error(`Failed to update the asset.\n${err.response.data.message}.`);
    }
  }, 200);
  deleteAsset = async () => {
    if (this.state.beacons.length) {
      this.setState({
        isDeleteModalOpen: true,
      });
    } else {
      let userResponsePromise = new Promise((resolve, reject) => {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure?',
          text: 'This Asset will be deleted.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
          .then((submit) => {
            resolve(submit.isConfirmed);
          })
          .catch((err) => {
            reject(err);
          });
      });

      let userResponse;

      try {
        userResponse = await userResponsePromise;
      } catch (error) {
        userResponse = false;
        console.log(error);
      }

      if (userResponse) {
        const { assetId } = this.props.match.params;
        try {
          await Api.delete(`/assets/${assetId}`, {
            cancelToken: this.source.token,
          });
          this.props.history.push('/assets');
          toast.success('Asset was successfully deleted!');
        } catch (error) {
          toast.error('Failed to delete the asset.');
        }
      }
    }
  };

  refetch = () => {
    this.getData({ useLoading: false });
  };

  onAddScanIn = async ({ value, notes }) => {
    try {
      await Api.post(
        '/scans',
        {
          created: new Date().toISOString(),
          assetId: this.state.asset.id,
          value,
          notes,
        },
        {
          cancelToken: this.source.token,
        },
      );

      this.refetch();
      toast.success('Created scan!');
    } catch (error) {
      toast.error('Failed to add scan in.');
      throw error;
    }
  };

  abortAll() {
    this.source.cancel('Request cancelled by user');
    this.source = this.cancelToken.source();
  }

  render() {
    if (this.state.error) {
      // handle AssetDeletedError

      if (this.state.error.name === 'AssetDeletedError') {
        return (
          <div className="h-100 d-flex flex-column justify-content-around align-items-center">
            <Text as="h1" variant="danger" className="fs-2x">
              The Asset{' '}
              <span className="asset-name-primary">
                {this.state.asset.name ? this.state.asset.name : ''}
              </span>{' '}
              has been deleted.
            </Text>

            <Button className="retry-btn" onClick={() => this.props.history.goBack()}>
              Return to Previous Page
            </Button>
          </div>
        );
      }

      // handle generic error
      return (
        <div className="h-100 d-flex flex-column justify-content-around align-items-center">
          <Text as="h1" variant="danger" className="fs-2x">
            Request failed. Please try again
          </Text>

          <Button className="retry-btn" onClick={this.getData}>
            Retry
          </Button>
        </div>
      );
    }

    if (this.state.isLoading) return <Loading />;

    return (
      <>
        <DeleteModal
          isOpen={this.state.isDeleteModalOpen}
          toggle={() => this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })}
          asset={this.state.asset}
          beacons={this.state.beacons}
        />
        <AssetScreen
          pageType={'assetDetails'}
          asset={this.state.asset}
          keywords={this.state.keywords}
          identifiers={this.state.identifiers}
          beacons={this.state.beacons}
          selectedBeacons={this.state.selectedBeacons}
          map={this.state.map}
          location={this.state.location}
          onChangeKeywords={this.onChangeKeywords}
          triggers={this.state.triggers}
          getBeaconReadings={this.getBeaconReadings}
          getBeaconReadingsExport={this.getBeaconReadingsExport}
          places={this.state.places}
          isFavorite={this.state.isFavorite}
          favoriteAsset={this.favoriteAsset}
          unfavoriteAsset={this.unfavoriteAsset}
          setAsset={this.setAsset}
          deleteAsset={this.deleteAsset}
          onChangeIdentifiers={this.onChangeIdentifiers}
          refetch={this.refetch}
          scans={this.state.scans}
          onAddScanIn={this.onAddScanIn}
          updateAsset={this.updateAsset}
        />
      </>
    );
  }
}

class AssetDeletedError extends Error {
  constructor(message = 'Asset is deleted.') {
    super(message);
    this.name = 'AssetDeletedError';
  }
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(withRouter(AssetContainer));
