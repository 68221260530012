import './AdminUsers.scss';
import { Icon, Text } from '@components';
import { PagedQueryParams, ServerSideGrid } from '@components/grids/AgGrid/ServerSideGrid';
import { useDock } from '@react';
import { Api, CustomApi } from '@services';
import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { PagedModel } from '../../../models/PagedModel';
import { emitter, EVENTS } from '../../userMgmt/utils';
import AdminAddUser from './AdminAddUser';
import AdminUserAppSelector from './AdminUserAppSelector';
import { adminUsersColumnDefs } from './columnDefs';

function useOutsideAlerter(ref, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack]);
}

function AdminUsers() {
  const dockRef = useRef(null);
  //useState Hooks
  const [rowData, setRowData] = useState(new PagedModel());
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [appList, setAppList] = useState();
  const [appIdSelected, setAppIdSelected] = useState();
  const [gridKey, setGridKey] = useState(0);
  const { renderDock, closeDock } = useDock();

  useOutsideAlerter(dockRef, closeDock);

  // Grabbing the app name selected to use for CSV Title
  const selectedApp = appList?.find((app) => app.id === appIdSelected);
  const appNameSelected = selectedApp ? selectedApp.name : 'Default App Name';

  const cancelToken = CustomApi.CancelToken;
  let fetchDataCancel;

  const id = 'AdminPortalUsers';

  const renderAddUser = (inviteType, appIdSelected) => {
    renderDock({
      render: () => (
        <AdminAddUser
          ref={dockRef}
          closeDock={closeDock}
          inviteType={inviteType}
          appIdSelected={appIdSelected}
          appNameSelected={appNameSelected}
        />
      ),
    });
  };

  const fetchAppList = (payload, csvExport, queryState) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    CustomApi.get(`api/admin/applications?top=${2000}`, {
      cancelToken: new cancelToken(function executor(c) {
        fetchDataCancel = c;
      }),
    })
      .then((data) => {
        let resp = data.data.items;
        setAppList(resp);
      })
      .catch((e) => {});
  };

  const fetchData = async (payload, csvExport, queryState) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);

    const requestData = {
      search: queryState.filter,
      sort: 'username asc', // Adjust this based on your sorting requirements
    };

    Api.get(
      `/applications/${appIdSelected}/users?top=${
        csvExport ? rowData.count : queryState.pageSize
      }&skip=${queryState.skip()}&search=${queryState.filter}&orderBy=${queryState.orderBy()}`,
      {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      },
    )
      .then((data) => {
        let resp = data.data;
        setRowData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });

    // Help create call to export Admin Portal Users
    if (csvExport) {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      };

      Api.post(`/applications/${appIdSelected}/users/export/`, requestData, config)
        .then((res) => {
          setCsvFileName('Users_Export');
          setCsvData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  emitter.on(EVENTS.REFETCH_USERS, () => {
    setGridKey((prevKey) => prevKey + 1);
  });

  useEffect(() => {
    // Reset the grid key whenever appIdSelected changes to force a reload of serversidegrid
    setGridKey((prevKey) => prevKey + 1);
  }, [appIdSelected]);
  // Fetch the app list for dropdown
  useEffect(() => {
    fetchAppList();
  }, []);

  return (
    <div className="h-100">
      <Card className="mb-3">
        <CardBody>
          <Row>
            <Col sm={12} md={12} lg={5} xl={4}>
              <AdminUserAppSelector appList={appList} setAppIdSelected={setAppIdSelected} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="admin-portal-user-card">
        <Card.Body>
          <Row>
            <Col>
              {appIdSelected && (
                <div className="d-flex flex-row justify-content-end">
                  <DropdownButton
                    id="Invite Users"
                    className=""
                    variant="secondary"
                    title="Invite Users"
                  >
                    <Dropdown.Item as="button" onClick={() => renderAddUser('CSV', appIdSelected)}>
                      Via CSV
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => renderAddUser('EMAIL', appIdSelected)}
                    >
                      Via Email
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )}
            </Col>
          </Row>
          <Row className="">
            <Col className="data-wrap">
              {appIdSelected && (
                <ServerSideGrid
                  key={gridKey}
                  id={id + '-' + appNameSelected}
                  columnDefs={adminUsersColumnDefs(appIdSelected)}
                  rowData={rowData}
                  csvData={csvData}
                  csvFileName={csvFileName}
                  isLoading={isLoading}
                  disabledFilters={[]}
                  filterControl={false}
                  searchControl={true}
                  viewingControl={true}
                  pagerControl={true}
                  exportControl={true}
                  initQueryParams={new PagedQueryParams(id)}
                  handleFetchData={fetchData}
                  gridProps={{
                    frameworkComponents: {},
                    suppressClickEdit: true,
                    editType: 'fullRow',
                  }}
                />
              )}

              {!appIdSelected && (
                <div className="user-select-msg h-100 w-100 d-flex flex-column justify-content-center align-items-center p-0">
                  <Icon
                    name="users"
                    size="5x"
                    className="mb-3"
                    variant="dark"
                    disabled={false}
                    showPointer={false}
                  />
                  <Text className="mb-3" as="h5">
                    Select an Application to display Users
                  </Text>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminUsers;
