import '../../MapMenu.scss';
import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Icon, Text } from '../../..';
export function MapCreateStationary({
  selected,
  newObjCreated,
  setIsCreating,
  setCreateStationary,
  setObjectId,
  setObjectName,
  setObjectFloor,
  setObjectKeywords,
  setObjectOnline,
  setAddToMap,
  setRemoveTempItem,
}) {
  const [name, setName] = useState<string>('');
  const [floor, setFloor] = useState<string>('1');
  const [id, setId] = useState<string>(null);
  const [isOnline, setIsOnline] = useState<boolean>(null);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<any>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  useEffect(() => {
    setObjectId(id);
  }, [id, setObjectId]);

  useEffect(() => {
    setObjectName(name);
  }, [name, setObjectName]);

  useEffect(() => {
    setObjectFloor(floor);
  }, [floor, setObjectFloor]);

  useEffect(() => {
    setObjectKeywords(keywords);
  }, [keywords, setObjectKeywords]);

  useEffect(() => {
    setObjectOnline(isOnline);
  }, [isOnline, setObjectOnline]);

  useEffect(() => {
    newObjCreated !== null ? setShowSave(true) : setShowSave(false);
  }, [newObjCreated]);

  const handleInput = (items) => {
    if (items.length > 0) {
      setId(items[0].id.toString());
      setName(items[0].label);
      setIsOnline(items[0].isOnline);
      setIsInvalid(false);
    } else {
      setId('');
      setName('');
      setIsOnline(null);
      setIsInvalid(true);
    }
  };

  // Handle submission of item
  function startDraw() {
    setCreateStationary(true);
  }

  const getResults = async (call) => {
    const resp = await Api.get<any[]>(call);
    const newItems = resp.data['items'].map((i) => ({
      id: i.id,
      name: i.name,
    }));
    const next = resp.data['next'];
    const total = resp.data['count'];
    return { newItems, next, total };
  };

  const handleSearch = (value) => {
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      // get and cache new query items
      const initialCall = `/assets?top=30&skip=0&filter=${value}&orderBy=name`;
      setIsLoading(true);
      getResults(initialCall).then((resp) => {
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems, next: resp.next, total: resp.total },
        });
        setIsLoading(false);
      });
    }
  };

  const handlePagination = () => {
    // if query cache length is less than total
    // continue to fetch new items on pagination using cached next api call
    console.log(cache[query].items.length, cache[query].total);
    if (cache[query].items.length !== cache[query].total) {
      console.log('here');
      setIsLoading(true);
      getResults(cache[query].next).then((resp) => {
        const items = cache[query].items.concat(resp.newItems);
        setOptions(items);
        setCache({ ...cache, [query]: { items: items, next: resp.next, total: resp.total } });
        setIsLoading(false);
      });
    }
  };

  return (
    <Form className={selected !== 'stationary' ? 'object-wrapper' : 'object-wrapper-expand'}>
      <Card.Body>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <Text className="fw-bold fs-md">Asset Search:</Text>
            <div>
              {showSave === true ? (
                <Icon
                  name={'check'}
                  title="Confirm"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={() => {
                    setAddToMap(true);
                    setIsCreating(false);
                    setCreateStationary(false);
                    setShowSave(false);
                  }}
                />
              ) : (
                <Icon
                  name={'pin-edit'}
                  title="Place Stationary Asset"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={() => {
                    startDraw();
                  }}
                />
              )}

              <Icon
                name={'add'}
                title="Close"
                className="sonarIcon-rotate-45 align-sub nav-text-icon"
                variant="light"
                disabled={false}
                size="md"
                onClick={() => {
                  setCreateStationary(false);
                  setIsCreating(false);
                  setRemoveTempItem(true);
                }}
              />
            </div>
          </div>
          <div className="input-placeholder mb-3" />
          <div className="d-flex align-items-center justify-content-between mb-3 typeahead-menu">
            <AsyncTypeahead
              allowNew={false}
              filterBy={['label', 'id']}
              delay={500}
              id="stationary"
              maxResults={10}
              minLength={1}
              isInvalid={isInvalid}
              isLoading={isLoading}
              onChange={(e) => {
                handleInput(e);
              }}
              onPaginate={handlePagination}
              onSearch={handleSearch}
              options={
                options &&
                options.map((option) => {
                  return {
                    id: option.id,
                    label: option.name,
                  };
                })
              }
              placeholder="Search for an Asset..."
              renderMenuItemChildren={(option, { text }) => (
                <div title={option.label}>
                  <Highlighter search={text}>{option.label}</Highlighter>
                  <div className="d-flex flex-column">
                    <small className="filter-sm-name">ID: {option.id}</small>
                  </div>
                </div>
              )}
              useCache={false}
            />
          </div>
        </div>
      </Card.Body>
    </Form>
  );
}
