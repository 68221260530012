import { Api } from '@services';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Loading, toast } from '../../../components';
import { MapObject } from '../../../components/MapsV2';
import { useApp } from '../../../react';
import JoyRide from 'react-joyride';
import { SonarMapTourModal } from '../../../components/layout/SonarMapTour';
import '../../../components/layout/SonarMapTour/SonarMapTourModal.scss';
import { isAdmin, isReadOnly, isSystemAdmin, isSuperAdmin, isUser } from '@utils';
import {
  AdminSteps,
  ReadOnlySteps,
  UserSteps,
} from '../../../components/layout/SonarMapTour/SonarMapTourSteps';

async function fetchMapImage({ mapId }) {
  const response = await Api.get(`v2/maps/${mapId}/image`);
  return response.config.url;
}

async function fetchMapOveraly({ mapId }) {
  const { data } = await Api.get(`v2/maps/${mapId}`);
  return data;
}

async function fetchMapGateways(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/gateways/filter`, payload);
  return data;
}

async function fetchMapPlaces(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/places/filter`, payload);
  return data;
}

async function fetchMapAssets(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/assets/filter`, payload);
  return data;
}

async function fetchMapStationary(mapId, payload) {
  const { data } = await Api.post(`v2/maps/${mapId}/stationary-assets/filter`, payload);
  return data;
}

// Define the current map tour version (increment this when a new version is released)
const MAP_TOUR_VERSION = 'v1'; // Current version of the map tour

export function MapDetailV2() {
  const app = useApp();
  const location = useLocation();
  const urlArr = location.pathname.split('/');
  const mapId =
    urlArr[urlArr.length - 1] === 'edit' ? urlArr[urlArr.length - 2] : urlArr[urlArr.length - 1];

  // Map feature collections
  const [mapImg, setMapImg] = useState<any>(null);
  const [newMapImg, setNewMapImg] = useState<any>(null);
  const [overlayFC, setMapOverlayFC] = useState<any>(null);
  const [placeFC, setPlaceFC] = useState<any>(null);
  const [gatewayFC, setGatewayFC] = useState<any>(null);
  const [assetFC, setAssetFC] = useState<any>(null);
  const [stationaryFC, setStationaryFC] = useState<any>(null);
  const [scale, setScale] = useState<any>(null);
  const [placeList, setPlaceList] = useState<any>(null);
  const [assetList, setAssetList] = useState<any>(null);
  const [gatewayList, setGatewayList] = useState<any>(null);
  const [stationaryList, setStationaryList] = useState<any>(null);
  const [isTourStarted, setIsTourStarted] = useState(false);
  const [showMapTourModal, setShowMapTourModal] = useState(false);

  // Check local storage to see if the user has already seen the current map tour
  useEffect(() => {
    const seenTourVersion = localStorage.getItem('mapTourVersion');

    // If the user hasn't seen this version of the tour, show the modal
    if (seenTourVersion !== MAP_TOUR_VERSION) {
      const showTourModalTimeout = setTimeout(() => {
        setShowMapTourModal(true);
      }, 1000); // Delay before showing the modal

      return () => clearTimeout(showTourModalTimeout); // Clear timeout on component unmount
    }
  }, []);

  // Start the tour and mark the tour as seen
  const startMapTour = () => {
    setShowMapTourModal(false);
    setIsTourStarted(true);

    // Store the current version in localStorage to mark the tour as seen
    localStorage.setItem('mapTourVersion', MAP_TOUR_VERSION);
  };

  // Skip the tour and mark the tour as skipped
  const skipMapTour = () => {
    setShowMapTourModal(false);

    // Store the current version in localStorage to mark the tour as skipped
    localStorage.setItem('mapTourVersion', MAP_TOUR_VERSION);
  };

  const customToolTip = ({
    continuous,
    index,
    isLastStep,
    size,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: iCustomToolTip) => {
    const tooltipArrowColor =
      step.title === 'Tools Menu' || step.title === 'Map Layers' || step.title === 'Info Menu'
        ? 'white'
        : 'var(--primary-to-black)';
    return (
      <div className="custom-tool-tip-tour" {...tooltipProps}>
        <div className="c-tool-tip-blue">
          <span className="c-tool-tip-title">{step.title && <h6>{step.title}</h6>}</span>

          <span
            className="c-tool-tip-exit"
            {...skipProps}
            onClick={() => {
              // setTourStarted(false);
              setIsTourStarted(false);
              localStorage.removeItem('newUserConfirmation');
            }}
          >
            <i className="sonarIcon sonarIcon-add fs-md"></i>
          </span>
        </div>

        <div className="c-tool-tip-content">
          {step.content && <div>{step.content}</div>}
          <div className="c-tool-tip-footer">
            <div>
              {index + 1}/{size}
            </div>
            {index > 0 && (
              <div className="c-tool-back" {...backProps}>
                <i className="sonarIcon sonarIcon-left-arrow fs-sm"></i>
              </div>
            )}
            {!isLastStep ? (
              <div className="c-tool-next" {...primaryProps}>
                <i className="sonarIcon sonarIcon-right-arrow fs-sm"></i>
              </div>
            ) : (
              <div className="c-tool-next" {...primaryProps}>
                <i className="sonarIcon sonarIcon-check fs-sm"></i>
              </div>
            )}
          </div>
        </div>
        {/* Setting arrow color dynamically */}
        <style>
          {`
          .__floater__arrow > span > svg > polygon {
            fill: ${tooltipArrowColor} !important;
          }
        `}
        </style>
      </div>
    );
  };

  // Map data queries
  const initImg = useQuery([`map-${mapId}-image`], async () => await fetchMapImage({ mapId }), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMapImg(data);
    },
  });

  const mapOverlayRes = useQuery([`map-${mapId}-overlay`], () => fetchMapOveraly({ mapId }), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMapOverlayFC(data);
    },
  });

  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const reset = () => {
    initImg.refetch();
    mapOverlayRes.refetch();
    setAssetList(null);
    setAssetFC(null);
    setPlaceList(null);
    setPlaceFC(null);
    setGatewayList(null);
    setGatewayFC(null);
    setStationaryList(null);
    setStationaryFC(null);
    setScale(null);
  };

  const resetAsset = () => {
    setAssetList(null);
    setAssetFC(null);
  };

  const resetPlace = () => {
    setPlaceList(null);
    setPlaceFC(null);
  };

  const resetGateway = () => {
    setGatewayList(null);
    setGatewayFC(null);
  };

  const resetStationary = () => {
    setStationaryList(null);
    setStationaryFC(null);
  };

  const saveMap = async () => {
    try {
      await Api.put(`/v2/maps/${mapId}`, overlayFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/gateways`, gatewayFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/places`, placeFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      await Api.put(`/v2/maps/${mapId}/stationary-assets`, stationaryFC, {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      });

      if (scale) {
        await Api.post(`/v2/maps/${mapId}/scale`, scale, {
          cancelToken: new cancelToken(function executor(c) {
            fetchDataCancel = c;
          }),
        });
      }

      // if (newMapImg) {
      //   let formData = new FormData();
      //   formData.append('file', newMapImg);
      //   await Api.put(`api/v2/maps/${mapId}/image`, formData, {
      //     cancelToken: new cancelToken(function executor(c) {
      //       fetchDataCancel = c;
      //     }),
      //   });
      // }
      reset();
      toast.success('Map was successfully updated!');
    } catch (error) {
      console.log(error);
      toast.error('Failed to update the current map.');
    }
  };

  if (initImg.isFetching || mapOverlayRes.isFetching || !overlayFC || !mapImg) return <Loading />;

  return (
    <>
      {/* Show the map tour modal */}
      <SonarMapTourModal
        show={showMapTourModal}
        onStartTour={startMapTour}
        onSkipTour={skipMapTour}
      />
      {/* JoyRide for Map Tour */}
      {isTourStarted && (
        <JoyRide
          steps={
            isUser()
              ? UserSteps
              : isReadOnly()
              ? ReadOnlySteps
              : isSystemAdmin()
              ? AdminSteps
              : isAdmin()
              ? AdminSteps
              : isSuperAdmin()
              ? AdminSteps
              : ''
          }
          continuous
          showProgress
          showSkipButton
          disableOverlayClose
          scrollToFirstStep
          tooltipComponent={customToolTip}
          locale={{
            last: 'Finish',
            skip: 'Skip',
          }}
          styles={{
            options: {
              backgroundColor: 'var(--primary)',
              primaryColor: 'var(--primary)',
              textColor: '#fff',
              zIndex: 1000,
            },
          }}
        />
      )}
      <MapObject
        app={app}
        mapId={mapId}
        urlArr={urlArr}
        mapImg={mapImg}
        overlayData={overlayFC}
        placeData={placeFC}
        gatewayData={gatewayFC}
        assetData={assetFC}
        stationaryData={stationaryFC}
        placeList={placeList}
        assetList={assetList}
        gatewayList={gatewayList}
        stationaryList={stationaryList}
        updateMapFC={(e) => setMapOverlayFC(e)}
        updatePlaceFC={(e) => setPlaceFC(e)}
        updateGatewayFC={(e) => setGatewayFC(e)}
        updateStationaryFC={(e) => setStationaryFC(e)}
        updateScale={(e) => setScale(e)}
        fetchAssetData={(e) => {
          fetchMapAssets(mapId, e).then((data) => {
            setAssetFC(data);
            setAssetList(data);
          });
        }}
        fetchPlaceData={(e) => {
          fetchMapPlaces(mapId, e).then((data) => {
            setPlaceFC(data);
            setPlaceList(data);
          });
        }}
        fetchGatewayData={(e) => {
          fetchMapGateways(mapId, e).then((data) => {
            setGatewayFC(data);
            setGatewayList(data);
          });
        }}
        fetchStationaryData={(e) => {
          fetchMapStationary(mapId, e).then((data) => {
            setStationaryFC(data);
            setStationaryList(data);
          });
        }}
        resetAsset={() => resetAsset()}
        resetPlace={() => resetPlace()}
        resetGateway={() => resetGateway()}
        resetStationary={() => resetStationary()}
        setMapImg={(e) => setNewMapImg(e)}
        reset={() => {
          reset();
        }}
        saveMap={() => saveMap()}
      />
    </>
  );
}
