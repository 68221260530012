import '../containers/breadcrumbsContainer.scss';
import { Loading, Pager, StartEndDatePicker } from '@components';
import { createTheme } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';

class BreadcrumbsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.limit,
      page: props.page,
      sortOrder: 1,
      sortField: null,
      startDate: props.startDate,
      endDate: props.endDate,
      locations: props.locations,
      currentlySelected: props.current,
      start: 0,
      end: 1000,
      arr: [],
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleSort(columnName) {
    let { sortField, sortOrder } = this.state;

    if (this.isSortedBy(columnName)) {
      sortOrder = sortOrder === 1 ? -1 : 1;
    } else {
      sortField = columnName;
      sortOrder = 1;
    }

    this.setState({ sortField: sortField, sortOrder: sortOrder }, () => this.updateParent());
  }

  handleChange(name, event) {
    let value = event.target?.value ?? event.toString();

    // Remove non-numeric characters and periods from the input value
    value = value.replace(/[^0-9]/g, '');

    // If the input value is empty or contains only non-numeric characters, set it to 0
    if (!value) {
      value = '0';
    }

    // If the input value starts with 0 but is not equal to 0, remove leading zeros
    if (value.startsWith('0') && value !== '0') {
      value = value.replace(/^0+/, '');
    }

    const { totalItems } = this.props;

    // If the entered value is larger than totalItems, set it to totalItems
    if (parseInt(value) > totalItems) {
      value = totalItems.toString();
    }

    // Update the state with the processed value
    this.setState({ [name]: value }, () => {
      if (name === 'limit') {
        // Set page to '1' only when the limit changes
        this.setState({ page: '1' }, () => {
          // Call updateParent after both limit and page have been updated
          this.updateParent();
        });
      } else if (name === 'page' && value === '0') {
        // If the name is 'page' and the value is '0', do not call updateParent()
        return;
      } else {
        // Call updateParent for other state changes
        this.updateParent();
      }
    });
  }

  handleKeyDown(e) {
    if (e.key === '-') {
      e.preventDefault();
    }
  }

  updateParent() {
    const { startDate, endDate, sortOrder, sortField, page, limit } = this.state;
    this.props.onQueryChanged(page, limit, sortOrder, sortField, startDate, endDate);
  }

  sortIcon(columnName) {
    let { sortOrder } = this.state;
    if (this.isSortedBy(columnName)) {
      return sortOrder === 1 ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      );
    }
    return;
  }

  isSortedBy(columnName) {
    let { sortField } = this.state;
    return sortField === columnName;
  }

  handleDateChange(name, val) {
    this.setState({ [name]: moment(val) }, () => {
      // Callback function to execute after state has been updated
      this.updateParent();
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.current) {
      return {
        currentlySelected: nextProps.current,
      };
    } else {
      return {
        currentlySelected: nextProps.current,
        start: 0,
        end: nextProps.locations.length,
        arr: nextProps.locations,
      };
    }
  }

  //Run anytime any state or prop changes
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.current !== this.props.current) {
      let start = 0;
      let end = this.props.locations.length;
      if (this.props.current) {
        let count = Math.floor(this.props.current / 50);
        start = count * 50;
        end = (count + 1) * 50 - 1;
        if (end > this.props.locations.length - 1) {
          end = this.props.locations.length - 1;
        }
      }
      let arr = this.props.locations.slice(start, end + 1);
      if (start !== prevState.start && end !== prevState.end) {
        this.setState({
          start,
          end,
          arr,
        });
      }
    }

    if (prevProps.totalItems !== this.props.totalItems) {
      let limit = this.props.totalItems > 50 ? '50' : this.props.totalItems;
      this.setState({ limit }, () => {
        this.updateParent();
      });
    }
  }

  render() {
    const { isLoading } = this.props;
    let pageCount = Math.ceil(parseInt(this.props.totalItems) / this.state.limit);

    if (isNaN(pageCount) || !isFinite(pageCount) || pageCount < 0) {
      pageCount = 0;
    }

    return (
      <div className="breadcrumb-input-table-upper-wrapper">
        <div className="date-number-input-container d-flex justify-content-between">
          <StartEndDatePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChangeStartDate={(date) => this.handleDateChange('startDate', date)}
            onChangeEndDate={(date) => this.handleDateChange('endDate', date)}
            isBreadCrumbDates={true}
          />
          <div className="number-input-container">
            <span>
              <input
                type="number"
                value={this.state.limit}
                min={0}
                max={this.props.totalItems}
                onKeyDown={this.handleKeyDown}
                onChange={(e) => this.handleChange('limit', e)}
              />{' '}
              of {this.props.totalItems}
            </span>
          </div>
          <Pager
            page={parseInt(this.state.page)}
            pageCount={parseInt(pageCount)}
            onChange={(e) => this.handleChange('page', e)}
          />
        </div>
        {isLoading ? (
          <div className="loading-wrapper d-flex justify-content-center">
            {' '}
            <Loading />
          </div>
        ) : (
          <div className="scroll-table">
            <table className="table table-sm ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    onClick={() => this.handleSort('timestamp')}
                    className={`cursor-pointer ${
                      this.isSortedBy('timestamp') ? 'cg-table-colClicked' : 'cg-table-col'
                    }`}
                  >
                    Timestamp {this.sortIcon('timestamp')}
                  </th>
                  <th
                    scope="col"
                    onClick={() => this.handleSort('distanceMoved')}
                    className={`cursor-pointer ${
                      this.isSortedBy('distanceMoved') ? 'cg-table-colClicked' : 'cg-table-col'
                    }`}
                  >
                    Distance Moved {this.sortIcon('distanceMoved')}
                  </th>
                </tr>
              </thead>
              {this.props.locations.length === 0 ? (
                ''
              ) : (
                <tbody>
                  {this.props.locations.length > 0
                    ? this.state.arr.map((location, index) => {
                        return (
                          <tr
                            key={`${location.index} + ${this.state.current}`}
                            onClick={() => this.props.onRowSelect(location.index)}
                            className={`cursor-pointer ${
                              location.isHighlighted
                                ? 'cg-table-highlight'
                                : 'cg-table-highlight-default'
                            }`}
                          >
                            <td>{moment(location.timestamp).format('L LTS')}</td>
                            <td>
                              {this.props.units === 'm'
                                ? Math.round(location.distanceMoved * 3.281 * 0.3048)
                                : Math.round(location.distanceMoved * 3.281)}{' '}
                              {this.props.units}
                            </td>
                          </tr>
                        );
                      })
                    : false}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
    );
  }
}

BreadcrumbsTable.propTypes = {
  locations: PropTypes.array,
  totalItems: PropTypes.string,
  pageCount: PropTypes.string,
  allowFilter: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.number,
  limit: PropTypes.string,
  page: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onRowSelect: PropTypes.func,
  onQueryChanged: PropTypes.func,
};

export default BreadcrumbsTable;
